import { Col, Row } from "react-bootstrap";

function Footer() {
  return (
    <>
      <Row className="bg-black" style={{borderTop: "2px solid grey"}}>
        <Col xs={12} className=" text-center">
          <h5 className="text-light text-center p-3">
            © Copyright 2021 - Growmindz Technologies - All Rights Reserved
          </h5>
        </Col>
      </Row>
    </>
  );
}
export default Footer;
