import { Col, Row } from "react-bootstrap";

function Contact() {
  return (
    <>
      <Row className="bg-black p-5 mt-1" id="contactus">
        <Col xs={8} className=" text-center address px-5 ">
          <Row>
            <Col xs={6}>
              {" "}
              <h5 className="text-light text-start px-5"> Head Office</h5>
              <br />
              <p className="text-light text-start px-5">
                4/127-3, 1st Floor
                <br />
                V.M.Complex, anbu nagar
                <br />
                salem main road, Pudhansanthai,
                <br />
                sellampatty(po), Nammakal - 637019
                <br />
              </p>
            </Col>
            <Col xs={6}>
              {" "}
              <h5 className="text-light text-start px-5"> Branch Office</h5>
              <br />
              <p className="text-light text-start px-5">
                Meenakshi Nilaya, <br />
                3rd cross, 3rd Street
                <br />
                Madiwala, Bangalore
                <br />
                Karnataka - 560068 <br />
              </p>
            </Col>
          </Row>
          <Row>
            <h5 className="text-light text-center px-5 mt-3"> Contacts</h5>
            <br />

            <p className="text-light text-center px-5">
              Email: admin@Growmindz.com
              <br />
              Phone: +91 9916 2160 66
            </p>
          </Row>
        </Col>
        <Col xs={4} className=" text-center map p-2 ">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.2217210331605!2d78.16808167466529!3d11.318498248961994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babc52cd21d10f3%3A0x1814dedf214845d3!2sGrowmindz%20Technologies!5e0!3m2!1sen!2sin!4v1700712191322!5m2!1sen!2sin"
            width="100%"
            height="350"
            loading="lazy"
          ></iframe>
        </Col>
      </Row>
    </>
  );
}
export default Contact;
