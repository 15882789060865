import AboutUs from '../AboutUs';
import Contact from '../ContactForm';
import { Container } from "react-bootstrap";
import Home from '../Home';
import Industries from '../IndustriesDeal';
import Services from '../Services';

function MainContent(){

return(

<Container>
    <Home />
    <Services/>
    <Industries/>
    <AboutUs/>
    <Contact/>
    </Container>
)


}
export default MainContent