import { Col, Row } from "react-bootstrap";

function Home() {
  const bmright = {
    backgroundImage: 'url("images/car_wheel_right.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "75px",
    width: "100px",
    // Set the height as needed
  };
  const bmleft = {
    backgroundImage: 'url("images/car_wheel_left.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "67px",
    width: "85px",
    // Set the height as needed
  };
  return (
        <Row className="p-5 mt-3  home" id="home">
          <Col xs={12} className="text-center mt-5 ">
            <h1 className="text-center fw-bold ">Mobile | Analytic | Cloud</h1>
            <p style={{color:'white'}}>
              Forge ahead into the future through the realms of Enterprise
              Digitalization, shaping a transformative path for your business.{" "}
            </p>
          </Col>

          {/* <div class="car">
            <div class="tyre left" style={bmleft}>
              <div class="rim"></div>
            </div>
            <div class="tyre right" style={bmright}>
              <div class="rim"></div>
            </div>
          </div> */}
        </Row>
  );
}
export default Home;
