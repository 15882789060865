import "./App.css";

import Footer from "./Footer/index.js";
import Header from "./Header/index.js";
import MainContent from "./MainContent/index.js";

function App() {
  return (
    <div>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
