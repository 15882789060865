import { BiCloudUpload, BiSolidEdit } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { VscHome, VscSettingsGear, VscTag } from "react-icons/vsc";

import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsCashStack } from "react-icons/bs";
import { CiDeliveryTruck } from "react-icons/ci";

function Industries() {
  return (
    <>
      <Row id="industries">
        <h1 className="text-center fw-bold">Industries we deal with</h1>
        <Col xs={3} className="text-center mt-5 ">
          <AiOutlineShoppingCart className="h-50 w-50 icon-color p-2" />
          <h3>eCommerce</h3>
        </Col>
        <Col xs={3} className="text-center mt-5">
          <VscSettingsGear className="h-50 w-50 icon-color p-2" />
          <h3>Manufacturing</h3>
        </Col>
        <Col xs={3} className="text-center mt-5">
          <CiDeliveryTruck className="h-50 w-50 icon-color p-2" />
          <h3>Logistics</h3>
        </Col>
        <Col xs={3} className="text-center mt-5">
          <BsCashStack className="h-50 w-50 icon-color p-2" />
          <h3>Banking & Finance</h3>
        </Col>
      </Row>
      <Row className="p-3 mt-2">
        <Col xs={3} className="text-center">
          <VscHome className="h-50 w-50 icon-color p-2" />
          <br />
          <h3>Insurance</h3>
        </Col>
        <Col xs={3} className="text-center">
          <BiSolidEdit className="h-50 w-50 icon-color p-2" />
          <h3>Inventory</h3>
        </Col>
        <Col xs={3} className="text-center">
          <BiCloudUpload className="h-50 w-50 icon-color p-2" />{" "}
          <h3>Hosting</h3>
        </Col>
        <Col xs={3} className="text-center">
          <VscTag className="h-50 w-50 icon-color p-2" />
          <h3>Retail</h3>
        </Col>
      </Row>
    </>
  );
}
export default Industries;
