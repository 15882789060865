import { Col, Row } from "react-bootstrap";
import { VscGraphLine, VscNote, VscVm } from "react-icons/vsc";

import { AiFillAppstore } from "react-icons/ai";

function Services() {
  return (
    <Row className="d-flex justify-content-center text-center p-5" id="services">
      <h1 className="text-center fw-bold radiant ">Services</h1>
      <Col xs={3} className="justify-content-center text-start  ">
        <Row className="">
          <Col xs={4} className="mb-5  text-center">
            {" "}
            <VscNote className="h-w icon-color" />
          </Col>
          <Col xs={8} className="mrt">
            <h5>Organizational Digitalization</h5>
            <p>
              It is not about automation of simple tasks but digitizing the
              business process, the DNA of organization.
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="justify-content-center text-start  ">
        <Row className="">
          <Col xs={4} className="margin-bottom text-center">
            {" "}
            <VscVm className="h-w  icon-color" />
          </Col>
          <Col xs={8} className="mrt">
            <h5>Enterprise Solutions</h5>
            <p>
              Create intuitive Enterprise apps that stand out to sell your
              business. We deliver cross browser and high user experience apps
              and sites.
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="justify-content-center text-start  ">
        <Row className="">
          <Col xs={4} className="margin-bottom text-center">
            {" "}
            <AiFillAppstore className="h-w  icon-color" />
          </Col>
          <Col xs={8} className="mrt">
            <h5>High UX Mobile Apps</h5>
            <p>
              Build 'Mobile-First' apps with the latest technologies and tools.
              From native code to hybrid multi-platform apps, we do them all in
              OneSoft.
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="justify-content-center text-start  ">
        <Row className="">
          <Col xs={4} className="mb-5 text-center">
            {" "}
            <VscGraphLine className="h-w  icon-color" />
          </Col>
          <Col xs={8} className="mrt">
            <h5>Data Analytics and Robotics</h5>
            <p>
              Perform analytics on your data to get excellent insight into your
              business intricacies. Combine them with Machine Learning as well.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default Services;
