import { Col, Row } from "react-bootstrap";

function AboutUs() {
  return (
        <Row className=" about mt-3" id="aboutus">
          <h1 className="text-center fw-bold ">About Us</h1>
          <Col xs={12} className="text-start  p-5">
            <h3 className="p-3">Who we are</h3>
            <br />
            <p className="h6 px-2">
              Founded in 2021, Growmindz Technologies emerged as a dynamic force
              in the realm of software development and support. Our dedicated
              team comprises passionate professionals with diverse technological
              expertise, ranging from skilled web designers to seasoned
              enterprise architects. Together, we collaborate seamlessly to
              craft exceptional user experiences while actively engaging in both
              product and service-oriented initiatives.
            </p>
            <br />
            <h3 className="about p-3">Our Notable Works</h3>
            <br />
            <p className="h6 px-2">
              In our product lineup, 'BuddyMart' stands out as our flagship
              eCommerce solution. Renowned for its versatility, BuddyMart is
              adopted by various brands, allowing them to conduct
              intra-organizational peer-to-peer sales with added cross-billing
              functionalities. In the service sector, we proudly cater to the
              web technology requirements of TechVenture Group, a prominent
              business conglomerate with expansive operations across diverse
              industries. Our privileged position involves meeting and exceeding
              their technological needs, contributing to the seamless
              functioning of their digital landscape.
            </p>
            <br />
            <h3 className="about p-3">Why Growmindz</h3>
            <br />
            <p className="h6 px-2 text-start">
              * Optimize your business milestones by conserving valuable
              resources—time, finances, and effort—through streamlined
              technological deliveries. <br />
              * Deliver top-tier services and products to your valued
              stakeholders, fortified by cutting-edge validation support for
              unparalleled quality assurance.
              <br />* Experience superior support services characterized by
              precision, promptness, and innovative solutions, adept at swiftly
              addressing your application challenges.
            </p>
          </Col>
        </Row>
  );
}
export default AboutUs;
