import { VscHome, VscSearch } from "react-icons/vsc";

import { AiFillAppstore } from "react-icons/ai";
import { BsFillPinMapFill } from "react-icons/bs";
import Container from 'react-bootstrap/Container';
import { Image } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
      <Container fluid>
        <Navbar.Brand href="#home"><div className="App-logo"><Image src="images/logo.png" className="img-class"/> <span >Growmindz</span></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link href="#services"> <i>
                   <VscHome className="icon" />
                 </i>
                 <h5 className="d-inline"> &nbsp; Services</h5></Nav.Link>
            <Nav.Link eventKey={2} href="#industries">
            <i>
                  <AiFillAppstore className="icon" />
                </i>
                <h5 className="d-inline">&nbsp; Industries</h5>
            </Nav.Link>
            <Nav.Link eventKey={3} href="#aboutus">
            <i>
                  <VscSearch className="icon" />
                </i>
                <h5 className="d-inline">&nbsp; About us</h5>
            </Nav.Link>
            <Nav.Link eventKey={4} href="#contactus">
            <i>
                  <BsFillPinMapFill className="icon" />
                </i>
                <h5 className="d-inline">&nbsp; Contact us</h5>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
